import React from 'react'
import yellowTriangle from "../images/Group 495@2x.png"
import blueTriangle from "../images/Group 520.png";
export default function IconsAdded({iconColor}) {
  return (
    <div className='triangles-div'>
       <img
       className="triangle-yellow-icon"
       src={iconColor==="yellow"?yellowTriangle:blueTriangle}
       alt="yellow-triangle"
     />
     <img className="triangle-yellow-icon" src={iconColor==="yellow"?yellowTriangle:blueTriangle} alt="yellow-triangle" id='triangle-right-yellow-icon' />
    </div>
  )
}
