import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from '../../images/manual_testing-01.png'
import mobile_testing from '../../images/mobile_testing-01.png'
import saas_testing from '../../images/saas_testing-01.png'
import usability_testing from '../../images/usability_testing-01.png'
import web_testing from '../../images/web_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"
import FeaturedWork from "../../components/MOB_FeaturedWork"
import IconsAdded from "../../components/IconsAdded"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Streamline Your Business Operations with Advanced ERP Solutions</Title>
              <br />
              <p>
              Transform your organization with our comprehensive ERP solutions designed to 
              optimize processes, enhance productivity, and drive growth.  <br />
                <br />
                Our user-friendly software integrates key business functions, providing
                real-time insights and data-driven decision-making. Discover how our ERP
                solutions can elevate your business efficiency today!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>ERP Solution and Integration</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={manual_testing} img_alt={'manual_testing'} card_heading={'SCM'} card_para={'Enhance operational efficiency, reduce costs, and improve overall business performance. Streamline processes by automating various tasks, such as order processing, inventory management, and logistics coordination.'}/>
        <ServiceCard img_src={usability_testing} img_alt={'usability_testing'} card_heading={'HR and Payroll'} card_para={'Streamline HR processes, enhance employee management, and ensure compliance with regulations. Automate repetitive tasks such as payroll calculations, tax deductions, and benefits administration.'}/>
        <ServiceCard img_src={web_testing} img_alt={'web_testing'} card_heading={'Manufacturing'} card_para={'Enhance productivity, efficiency, and overall operational effectiveness. Manufacturing module streamline processes by automating repetitive tasks and optimizing workflows.'}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Accounting'} card_para={'Enhance financial management, improve accuracy, and streamline processes for businesses of all sizes. Accounting module  significantly reduce the likelihood of errors in financial records.'}/>
        <ServiceCard img_src={saas_testing} img_alt={'saas_testing'} card_heading={'Comprehensive Reporting'} card_para={'Enhance decision-making, operational efficiency, and strategic planning. Comprehensive reporting provides real-time insights into various business functions, allowing decision-makers to access up-to-date information.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      <div className="featured-projects position-relative">
        <FeaturedWork data={data} sharedFeature={{
          Title:"Microsoft Dynamics ERP re-vamp: Streamlined purchase, inventory, production, distribution & sales",
          descrip1:"Revitalize your Dynamics AX 2012 system with a comprehensive revamp. Enhance performance, unlock new features, and future-proof your ERP investment.",
          descrip2:"We combined process re-engineering and solution revamping to modify the Microsoft Dynamics ERP for one of our cake and bake business units after conducting a thorough investigation. In order to satisfy the unique requirements of cakes and bakes, this strategy improved efficiency and optimized operations.",
          caseStudyImage:data.erpSolCaseStudy.childImageSharp.fluid
          }}/>
          <IconsAdded/>
      </div>
      <DevelopmentProcess title='ERP Solution and Integration Services Process' >
        <ServiceStepsProcess process_heading='Discovery' process_para="Allows stakeholders to articulate their specific business processes, challenges, and objectives. Discuss which modules and functionalities are necessary for their operations." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Environment Setup' process_para="Setting up the environment for ERP implementation is a crucial phase that lays the foundation for a successful deployment. Ensure that the ERP system is configured correctly and ready for use." process_step_number="2" />
        <ServiceStepsProcess process_heading='User Acceptance Testing (UAT)' process_para="Final validation by end-users to ensure the application meets their needs. Gathering feedback from users to confirm that the application is ready for production." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Pilot Run' process_para="This approach helps identify potential issues, refine processes, and build confidence among stakeholders. Collect insights from users to understand their experiences and identify areas for improvement." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='User Training' process_para="It ensures that employees are well-equipped to use the new system effectively, which is essential for maximizing the benefits of the ERP solution." process_step_number="5" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Post Go-Live Support' process_para="Going live with an ERP system is a critical phase that requires effective support to ensure a smooth transition and ongoing operations." process_step_number="6" />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const  erpSolution= graphql`
  query erpSolution {
    erpSolCaseStudy: file(
      relativePath: { eq: "what-is-dynamics-365 1.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
